import React, { useEffect, useState } from 'react';
import {
    Container, Divider, IconButton, Paper, Box, TextField, Typography, Grid,
    LinearProgress, Toolbar, Button, Tooltip, Checkbox, FormControlLabel
} from '@mui/material';
import { Add, Refresh } from '@mui/icons-material';
import { BsSearch } from 'react-icons/bs';
import { useLocation } from 'react-router-dom';
import { Api } from '../../../../constant/api';
import SnakBar from '../../../../constant/message';
import color from '../../../../constant/colors';

export default function CollectEmi() {
    const location = useLocation();

    const [loading, setLoading] = useState(false);
    const [err, setErr] = useState({});
    const [massg, setMassg] = useState({});
    const [loanInfo, setLoanInfo] = useState([]);
    const [emiInfo, setEmiInfo] = useState([]);
    const [countAc, setCountAc] = useState([]);
    const [calculation, setCalculation] = useState({});
    const [query, setQuery] = useState('');
    const [checked, setChecked] = useState(true);
    const [info, setInfo] = useState({
        fine: '',
        rec_date: '',
        remark: '',
        emi_date: '',
        princ_amount: '',
        int_amount: '',
        ttl: ''
    });

    React.useEffect(() => {

        setInfo({
            fine: '',
            rec_date: '',
            remark: '',
            emi_date: ''
        })

        setLoading(true)
        const formData = new FormData
        formData.append('id', !location.state ? query : location.state)
        fetch(Api + 'emiInfo', {
            method: 'POST',
            body: formData
        })
            .then(res => res.json())
            .then(res => {

                setLoading(false)
                setInfo(res)
            })
            .catch(err => {
                setLoading(false)
                console.log(err)
            })


        fetch(Api + 'checkForEmiInof', {
            method: 'POST',
            body: JSON.stringify({
                ac_no: !location.state ? query : location.state
            })
        })
            .then(res => res.json())
            .then(res => {

                setLoanInfo(res.loan);
                setEmiInfo(res.emi);


            })
            .catch(err => {
                console.log(err)
            })

        fetch(Api + 'overdue')
            .then(res => res.json())
            .then(res => {

                setCountAc(res.count);

            })
            .catch(err => {
                console.log(err)
            });
    }, [location])

    useEffect(() => {
        handleSearchBtn();
    }, [loanInfo, emiInfo]);

    const handleSearch = (e) => {
        setLoading(true)
        setInfo({
            fine: '',
            rec_date: '',
            remark: '',
            emi_date: ''
        })
        setQuery(e.target.value)
        const formData = new FormData
        formData.append('id', e.target.value)
        fetch(Api + 'searchEmi', {
            method: 'POST',
            body: formData
        })
            .then(res => res.json())
            .then(res => {
                setInfo(res)
                setLoading(false)

                fetch(Api + 'checkForEmiInof', {
                    method: 'POST',
                    body: JSON.stringify({
                        ac_no: res.id
                    })
                })
                    .then(res => res.json())
                    .then(res => {
                        console.log(res)
                        setLoanInfo(res.loan)
                        setEmiInfo(res.emi)
                    })
                    .catch(err => {
                        console.log(err)
                    })

                fetch(Api + 'overdue')
                    .then(res => res.json())
                    .then(res => {

                        setCountAc(res.count);


                    })
                    .catch(err => {
                        console.log(err)
                    })



            })
            .catch(err => {
                setLoading(false)
                console.log(err)
            })
    }

    const handleSearchBtn = () => {
        setCalculation({
            Balance: calculateBalance(),
            Last_emi: lastReceived(),
            emi_date: emiDate(),
            overdue: overdueAmount(),
            no_of_due: noOfDue()
        });
    };

    const onEmiDateAdd = () => {
        setInfo({
            ...info,
            emi_date: emiDate()
        });
    };

    const handleChange = (e) => {
        const { name, value } = e.target;

        if (name === 'ttl') {
            const loanAmount = Number(loanInfo[0]?.ln_amnt || 0);
            const interestRate = Number(loanInfo[0]?.intrst || 0);
            if (interestRate > 10) {
                alert("Found abnormal interest rate, auto calculation couldn't work for this account");
            } else {
                const interestAmount = Number(loanInfo[0]?.tot_int || 0);
                const totalRecoverable = loanAmount + interestAmount;
                const emiAmount = totalRecoverable / (loanInfo[0]?.tenure || 1);
                const paidAmtInt = ((interestAmount / (loanInfo[0]?.tenure || 1)) / emiAmount) * Number(value);
                const paidAmtPrinc = Number(value) - paidAmtInt;
                console.log(loanInfo[0]?.tot_int)
                setInfo({
                    ...info,
                    princ_amount: paidAmtPrinc.toFixed(2).toString(),
                    int_amount: paidAmtInt.toFixed(2).toString(),
                    ttl: value
                });
            }
        } else {
            setInfo({
                ...info,
                [name]: value
            });
        }
    };

    const validate = () => {
        let valid = true
        let err = {}

        if (!info.id) {
            valid = false
            err['id'] = true
        }
        if (!info.princ_amount) {
            valid = false
            err['princ_amount'] = true
        }
        if (!info.int_amount) {
            valid = false
            err['int_amount'] = true
        }
        if (!info.rec_date) {
            valid = false
            err['rec_date'] = true
        }
        if (!info.r_no) {
            valid = false
            err['r_no'] = true
        }
        if (!info.emi_date) {
            valid = false
            err['emi_date'] = true
        }

        setErr(err)

        return valid
    }

    const onSubmit = () => {
        if (validate()) {
            setLoading(true)
            fetch(Api + 'emiUpdate', {
                method: 'POST',
                body: JSON.stringify({
                    ...info,
                    user: sessionStorage.getItem('id'),
                    checked: checked
                })
            })
                .then(res => res.json())
                .then(res => {
                    setLoading(false)

                    if (res.code === 200) {
                        setMassg({
                            open: true,
                            severity: 'success',
                            massg: res.massg
                        })
                    } else {
                        setMassg({
                            open: true,
                            severity: 'error',
                            massg: res.massg
                        })
                    }
                })
                .catch(err => {
                    setLoading(false)
                    setMassg({
                        open: true,
                        severity: 'error',
                        massg: 'Faild to connect to the server'
                    })
                })
        }
    }


    const calculateBalance = () => {
        const loanAmount = Number(loanInfo[0]?.ln_amnt || 0);
        const interest = Number(loanInfo[0]?.tot_int || 0) + Number(loanInfo[0]?.fc || 0) + Number(loanInfo[0]?.d_charge || 0);
        const total = loanAmount + interest;
        const receivedPrinc = Number(emiInfo[0]?.princ || 0);
        const receivedInt = Number(emiInfo[0]?.intr || 0);

        return total - (receivedPrinc + receivedInt);
    };

    const overdueAmount = () => {
        const princAmount = Number(loanInfo[0]?.princ_amount || 0);
        const intAmount = Number(loanInfo[0]?.int_amount || 0);
        return (princAmount + intAmount) * noOfDue();
    };

    const noOfDue = () => {
        if (emiInfo.length > 0) {
            return totalDue(emiInfo[0]?.emi_date, loanInfo[0]?.tenure, loanInfo[0]?.id);
        } else if (loanInfo.length > 0) {
            const openDate = loanInfo[0]?.open_date;
            return totalDue(openDate, loanInfo[0]?.tenure, loanInfo[0]?.id);
        }
        return null;
    };

    const totalDue = (emiDate, tenure, id) => {
        const date1 = new Date(emiDate);
        const date2 = new Date();
        const diffTime = Math.abs(date2 - date1);
        const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
        const month = Math.floor(diffDays / 30);
        const countEmi = emiInfo.filter((emi) => emi.ac_no === id).length;

        return month > tenure ? tenure - countEmi : month - countEmi;
    };

    const emiDate = () => {
        if (emiInfo.length > 0) {
            return new Date(emiInfo[0].emi_date).toLocaleDateString('en-CA');
        } else if (loanInfo.length > 0) {
            return new Date(loanInfo[0].open_date).toLocaleDateString('en-CA');
        }
        return '';
    };

    const lastReceived = () => {
        if (emiInfo.length > 0) {
            const lastReceivedDate = new Date(emiInfo[0]?.emi_date);
            const currentDate = new Date();
            const nextDate = new Date(lastReceivedDate.setMonth(lastReceivedDate.getMonth() + 1));
            return nextDate > currentDate ? nextDate.toLocaleDateString('en-CA') : currentDate.toLocaleDateString('en-CA');
        }
        return '';
    };

    return (
        <Container>
            <Paper>
                <Toolbar>
                    <Typography variant='h6'>Collect EMI</Typography>
                    <Box sx={{ flexGrow: 1 }} />
                    <IconButton onClick={() => window.location.reload()} color="primary">
                        <Refresh />
                    </IconButton>
                </Toolbar>
                <Divider />
                <Grid container spacing={2} sx={{ padding: 2 }}>
                    <Grid item xs={12} sm={11}>
                        <Box display="flex" alignItems="center">
                            <TextField
                                label='Enter Account No.'
                                variant='outlined'
                                value={query}
                                onChange={handleSearch}
                                error={!!err.ac_no}
                                helperText={err.ac_no && 'Account No is required'}
                                fullWidth
                                size='small'
                            />
                            <Tooltip title='Search Account'>
                                <IconButton onClick={handleSearch}>
                                    <BsSearch />
                                </IconButton>
                            </Tooltip>
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={1}>
                        {/* <Button onClick={onEmiDateAdd} size='small' variant='contained' color='primary'> */}
                        <Tooltip title="add emi date">
                            <IconButton onClick={onEmiDateAdd}>
                                <Add style={{ color: 'blue' }} />
                            </IconButton>
                        </Tooltip>
                        {/* </Button> */}
                    </Grid>
                    <Grid item xs={12}>
                        {loading && <LinearProgress />}
                    </Grid>
                </Grid>
                <Box sx={{ backgroundColor: color.secondary_light, display: 'flex', marginTop: 1, borderRadius: 5, padding: 1 }}>
                    <div style={{ width: '50%' }}>
                        <Typography style={{ margin: 5, fontSize: 14 }}>Balance : Rs.{Number(calculation.Balance).toFixed(2)}</Typography>
                        <Typography style={{ margin: 5, fontSize: 14 }}>Last Emi Recived at : {calculation.Last_emi}</Typography>
                        <Typography style={{ margin: 5, fontSize: 14 }}>Fine Amount: Rs.{calculation.no_of_due > 0 ? Number(calculation.no_of_due * 300).toFixed(2) : 0} </Typography>
                    </div>

                    <div style={{ width: '50%' }}>

                        <div style={{ display: 'flex' }}>
                            <Typography style={{ margin: 5, fontSize: 14 }}>
                                Emi Due Date : {calculation.emi_date}
                            </Typography>
                            <IconButton size="small" onClick={onEmiDateAdd} component={Paper}>
                                <Tooltip title="Click here to add emi date">
                                    <Add fontSize="10" color="primary" />
                                </Tooltip>
                            </IconButton>
                        </div>
                        <Typography style={{ margin: 5, fontSize: 14 }}>Overdue amount : Rs.{Number(calculation.overdue).toFixed(2)}</Typography>
                        <Typography style={{ margin: 5, fontSize: 14 }}>Number of due : {calculation.no_of_due}</Typography>
                    </div>
                </Box>
                <Grid container spacing={2} sx={{ padding: 2 }}>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            variant="outlined"
                            fullWidth
                            label="A/C Holder Name"
                            value={info.name}
                            onChange={handleChange}
                            disabled
                            InputLabelProps={{ shrink: true }}

                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            variant="outlined"
                            fullWidth
                            label="Ac Holder ACNO"
                            value={info.id}
                            onChange={handleChange}
                            disabled
                            InputLabelProps={{ shrink: true }}

                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            variant="outlined"
                            fullWidth
                            label="Proposal No"
                            value={info.proposal_no}
                            onChange={handleChange}
                            disabled
                            InputLabelProps={{ shrink: true }}

                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            label='Receipt No.'
                            name='r_no'
                            variant='outlined'
                            value={info.r_no}
                            onChange={handleChange}
                            error={!!err.r_no}
                            helperText={err.r_no && 'Receipt No is required'}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            variant="outlined"
                            fullWidth
                            label="EMI Amount"
                            value={Number(info.princ_amount) + Number(info.int_amount)}
                            onChange={handleChange}
                            disabled
                            InputLabelProps={{ shrink: true }}

                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            label='Change Emi Amount'
                            name='ttl'
                            variant='outlined'
                            value={info.ttl}
                            onChange={handleChange}
                            fullWidth
                            InputLabelProps={{
                                shrink: true
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            label='Principal Amount'
                            name='princ_amount'
                            variant='outlined'
                            value={info.princ_amount}
                            onChange={handleChange}
                            error={!!err.princ_amount}
                            helperText={err.princ_amount && 'Principal Amount is required'}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            label='Interest Amount'
                            name='int_amount'
                            variant='outlined'
                            value={info.int_amount}
                            onChange={handleChange}
                            error={!!err.int_amount}
                            helperText={err.int_amount && 'Interest Amount is required'}
                            fullWidth
                        />
                    </Grid>


                    <Grid item xs={12} sm={6}>
                        <TextField
                            label='Received Date'
                            name='rec_date'
                            type='date'
                            variant='outlined'
                            value={info.rec_date}
                            onChange={handleChange}
                            error={!!err.rec_date}
                            helperText={err.rec_date && 'Received Date is required'}
                            fullWidth
                            InputLabelProps={{
                                shrink: true
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            label='EMI Date'
                            name='emi_date'
                            type='date'
                            variant='outlined'
                            value={info.emi_date}
                            onChange={handleChange}
                            error={!!err.emi_date}
                            helperText={err.emi_date && 'EMI Date is required'}
                            fullWidth
                            InputLabelProps={{
                                shrink: true
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            label='Fine Amount'
                            name='fine'
                            variant='outlined'
                            value={info.fine}
                            onChange={handleChange}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            label='Remark'
                            name='remark'
                            variant='outlined'
                            value={info.remark}
                            onChange={handleChange}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <FormControlLabel
                            control={<Checkbox
                                checked={checked}
                                onChange={(e) => setChecked(e.target.checked)}
                                inputProps={{ 'aria-label': 'primary checkbox' }}
                            />}
                            label="Received the total amount"
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} display="flex" justifyContent="flex-end">
                        <Button onClick={onSubmit} variant='contained' color='primary'>
                            Submit
                        </Button>
                    </Grid>
                </Grid>
            </Paper>
            <SnakBar massg={massg} setMassg={setMassg} />
        </Container>
    );
}
