import { Button, Container, Divider, FormControl, Grid, InputLabel, LinearProgress, MenuItem, Paper, Select, TextField, Typography } from "@mui/material";
import React from "react";
import FileResizer from 'react-image-file-resizer'
import { Api } from "../../../constant/api";
import SnakBar from "../../../constant/message";


export default function AddEmployee(){
    const [data, setData] = React.useState({})
    const [err, setErr] = React.useState(false)
    const [loading,setLoading] = React.useState(false)
    const [massg,setMassg] = React.useState({})

    const handleChange = (e)=>{

        if(e.target.name === 'pass_img'){
            let files = e.target.files
           
            try {
                FileResizer.imageFileResizer(
                files[0],
                800,
                1000,
                "PNG",
                80,
                0,
                (uri) => {
                    setData({
                        ...data,
                        [e.target.name] : uri
                    })
                },
                "file",
                200,
                200
                );
          
            
            } catch (err) {
                console.log(err);
            }

        }else{
            setData({
                ...data,
                [e.target.name] : e.target.value
            })
        }
        
    }
    
    
    const validate = ()=>{
        let valid = true
        let err = {}

        if(!data.name){
            valid  = false
            err['name'] = true
        }
        if(!data.pass){
            valid = false
            err['pass'] = true
        }
        if(!data.role){
            valid = false
            err['role'] = true
        }

        
        setErr(err)

        return valid
    }

    const onSubmit = ()=>{
        if(validate()){
        setLoading(true)
        const post  = new FormData
        Object.keys(data).map((item,index)=>{
          
            post.append(item,data[item])
        })
        fetch(Api+'createEmployee',{
          method : 'POST',
          body : post
        })
        .then(res=>res.json())
        .then(res=>{
          setLoading(false)
          console.log(res)
          if(res.code === 200){
            setMassg({
              open : true,
              severity : 'success',
              massg : res.massg
            })
          }else{
            setMassg({
              open : true,
              severity : 'error',
              massg : res.massg
            })
          }
        })
        .catch(err=>{
          setLoading(false)
          setMassg({
            open : true,
            severity : 'error',
            massg : 'Faild to connect to the server'
          })
        })
        }
    }
    return(
        <Container maxWidth="sm">
            <Paper style={{padding: 10}}>
                <Typography variant="h5">Add New Employee</Typography>
                <Divider />
                {loading ? <LinearProgress /> : ''}
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            variant="outlined"
                            label="name"
                            name="name"
                            value={data.name}
                            error={err.name}
                            fullWidth
                            style={{marginTop: 5}}
                            size="small"
                            onChange={handleChange}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            variant="outlined"
                            label="F/H Name"
                            name="f_name"
                            value={data.f_name}
                            error={err.f_name} 
                            fullWidth
                            style={{marginTop: 5}}
                            size="small"
                            onChange={handleChange}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            variant="outlined"
                            label="Educational qualification"
                            name="edu_qualific"
                            value={data.edu_qualific}
                            error={err.edu_qualific}
                            fullWidth
                            style={{marginTop: 5}}
                            size="small"
                            onChange={handleChange}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            variant="outlined"
                            helperText="Passport Image"
                            name="pass_img"
                    
                            error={err.pass_img}
                            fullWidth
                            style={{marginTop: 5}}
                            size="small"
                            type="file"
                            onChange={handleChange}
                        />
                    </Grid>
                   
                    <Grid item xs={12} sm={6}>
                        <TextField
                            variant="outlined"
                            label="Password"
                            name="pass"
                            value={data.pass}
                            error={err.pass}
                            fullWidth
                            style={{marginTop: 5}}
                            size="small"
                            type="password"
                            helperText="This password is for agent app"
                            onChange={handleChange}
                        />
                    </Grid>
                    
                    <Grid item xs={12} sm={6}>
                    <FormControl required variant="outlined" style={{margin : 5}} size="small"  fullWidth>
                        <InputLabel id="demo-simple-select-outlined-label">Select Employee role</InputLabel>
                        <Select
                            labelId="demo-simple-select-outlined-label"
                            id="demo-simple-select-outlined"
                            error={err.role}
                            value={data.role}
                            onChange={handleChange}
                            label="Select Employee role"
                            name="role"
                        >
                        <MenuItem value={false}>
                            <em>None</em>
                        </MenuItem>
                        <MenuItem value="admin" >admin</MenuItem>
                        <MenuItem value="user" >user</MenuItem>
                        <MenuItem value="agent">agent</MenuItem>
                        </Select>
                    </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={onSubmit}
                        >
                            create account
                        </Button>
                    </Grid>
                    

                </Grid>

                <SnakBar massg={massg} setMassg={setMassg} />
            </Paper>
        </Container>
    )
}