import { Container, Divider, Table, TableBody, TableContainer, TableRow, Typography, Grid, Paper, LinearProgress } from "@mui/material";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import React from "react";
import { useLocation } from "react-router-dom";
import { Api, ImgApi } from "../../../../constant/api";


export default function AccountInformation(){
    const location = useLocation()
    const [data, setData] = React.useState({})
    const [loading,setLoading] = React.useState(false)
    React.useEffect(()=>{
        setLoading(true)
        const sendData = new FormData()
        sendData.append('id',location.state)
        fetch(Api+'holderDetails',{
            method : 'POST',
            body : sendData
        })
        .then(res=>res.json())
        .then(res=>{
            setData(res)
            setLoading(false)
        })
        .catch(err=>{
           console.log(err)
           setLoading(false)
        })
    },[location])

    return(
        <Container>
            {loading ? <LinearProgress /> : ''}
            
            <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                    <Paper variant="outlined" >
                    <Typography variant="h6" component={'strong'} style={{marginLeft: 10}} >Persional Information</Typography>
                    <Divider style={{marginTop : 10}} />
                    <TableContainer>
                        <Table
                            sx={{
                                [`& .${tableCellClasses.root}`]: {
                                  borderBottom: "none"
                                }
                              }}
                        >
                            <TableBody>
                                <TableRow>
                                    <TableCell>Name</TableCell>
                                    <TableCell>{data.name}</TableCell>   
                                </TableRow>
                                <TableRow>
                                    <TableCell>F/H Name</TableCell>
                                    <TableCell>{data.f_name}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>Contact no</TableCell>
                                    <TableCell>{data.ph_no}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>District</TableCell>
                                    <TableCell>{data.district}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>Address</TableCell>
                                    <TableCell>{data.vill + ","+ data.po + ","+ data.ps+ ","+ data.pin}</TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                    </Paper>
                </Grid>
                <Grid item xs={12} sm={6}>
                <Paper variant="outlined">
                    <Typography variant="h6" component={'strong'} style={{marginLeft: 10}}>Garuntor Information</Typography>
                    <Divider style={{marginTop : 10}} />
                    <TableContainer>
                        <Table
                            sx={{
                                [`& .${tableCellClasses.root}`]: {
                                  borderBottom: "none"
                                }
                              }}
                        >
                            <TableBody>
                                <TableRow>
                                    <TableCell>Name</TableCell>
                                    <TableCell>{data.g_name}</TableCell>   
                                </TableRow>
                                <TableRow>
                                    <TableCell>F/H Name</TableCell>
                                    <TableCell>{data.g_f_name}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>Address</TableCell>
                                    <TableCell>{(!data.g_vill ? '' : data.g_vill + ",") + (!data.g_po ? '' : data.g_po + ",")+ (!data.g_ps ? '' : data.g_ps + ",")+ (!data.g_pin ? '' : data.g_pin + ",")}</TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Paper>
                </Grid>
                <Grid item xs={12} sm={6}>
                <Paper variant="outlined">
                <Typography variant="h6" component={'strong'} style={{marginLeft: 10}}>Loan Information</Typography>
                    <Divider style={{marginTop : 10}} />
                    <TableContainer>
                        <Table
                            sx={{
                                [`& .${tableCellClasses.root}`]: {
                                  borderBottom: "none"
                                }
                              }}
                        >
                            <TableBody>
                                <TableRow>
                                    <TableCell>Proposal No</TableCell>
                                    <TableCell>{data.proposal_no}</TableCell>   
                                </TableRow>
                                <TableRow>
                                    <TableCell>Proposal Date</TableCell>
                                    <TableCell>{data.proposal_date}</TableCell>   
                                </TableRow>
                                <TableRow>
                                    <TableCell>EMI Starting Date</TableCell>
                                    <TableCell>{data.open_date}</TableCell>   
                                </TableRow>
                                <TableRow>
                                    <TableCell>Last EMI Date</TableCell>
                                    <TableCell>{data.last_emi_date}</TableCell>   
                                </TableRow>
                                <TableRow>
                                    <TableCell>Loan Amount</TableCell>
                                    <TableCell>{data.ln_amnt}</TableCell>   
                                </TableRow>
                                <TableRow>
                                    <TableCell>Interest Rate</TableCell>
                                    <TableCell>{data.int_rt}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>No of EMI</TableCell>
                                    <TableCell>{data.tenure}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>DC</TableCell>
                                    <TableCell>{data.d_charge}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>FC</TableCell>
                                    <TableCell>{data.fc}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>Total interest amount</TableCell>
                                    <TableCell>{data.tot_int}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>Total Recoverable amount</TableCell>
                                    <TableCell>{Number(data.tot_int)+Number(data.fc)+Number(data.d_charge)+Number(data.ln_amnt)}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>EMI  Amount</TableCell>
                                    <TableCell>{Number(data.princ_amount) + Number(data.int_amount)}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>EMI Interest Amount</TableCell>
                                    <TableCell>{data.int_amount}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>EMI Principle Amount</TableCell>
                                    <TableCell>{data.princ_amount}</TableCell>
                                </TableRow>
                               
                            </TableBody>
                        </Table>
                    </TableContainer>
                    </Paper>
                </Grid>
                <Grid item xs={12} sm={6}>
                <Paper variant="outlined">
                <Typography variant="h6" component={'strong'} style={{marginLeft: 10}}>Vehicle Information</Typography>
                    <Divider style={{marginTop : 10}} />
                    <TableContainer>
                        <Table
                            sx={{
                                [`& .${tableCellClasses.root}`]: {
                                  borderBottom: "none"
                                }
                              }}
                        >
                            <TableBody>
                                <TableRow>
                                    <TableCell>Vehicle Name</TableCell>
                                    <TableCell>{data.v_name}</TableCell>   
                                </TableRow>
                                <TableRow>
                                    <TableCell>Engine No</TableCell>
                                    <TableCell>{data.e_no}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>Chasis No</TableCell>
                                    <TableCell>{data.c_no}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>Registration No</TableCell>
                                    <TableCell>{data.r_no}</TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                    </Paper>
                    <Paper variant="outlined" style={{marginTop: 10}}>
                    <Typography variant="h6" component={'strong'} style={{marginLeft: 10}}>Document Information</Typography>
                        <Divider style={{marginTop : 10}} />
                        <TableContainer>
                            <Table
                                sx={{
                                    [`& .${tableCellClasses.root}`]: {
                                    borderBottom: "none"
                                    }
                                }}
                            >
                                <TableBody>
                                    <TableRow>
                                        <TableCell>Passport Image</TableCell>
                                        <TableCell>
                                            <img src={ImgApi+data.pass_img} style={{width : 200}} />   
                                        </TableCell>   
                                    </TableRow>
                                    
                                </TableBody>
                            </Table>
                        </TableContainer>
                        </Paper>
                </Grid>
                <Grid item xs={12} sm={6}>
                
                </Grid>
            </Grid>
        </Container>
    )
}