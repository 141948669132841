import { Button, Container, Table, TableBody, TableCell, TableContainer, TableRow, Paper, Toolbar, Typography } from "@mui/material";
import {useLocation } from 'react-router-dom'


export default function EmAccountDetails(){
    let data = useLocation()
    data = data.state
    


    return(
        <Container maxWidth="false" style={{padding:0}} >
            <Toolbar component={Paper} style={{display:'flex',justifyContent:'center',marginBottom:5}}>
                <Typography style={{fontWeight:'bold'}}>
                    Emloyee Details
                </Typography>
            </Toolbar>
            <TableContainer component={Paper} variant="outlined">
                <Table>
                    <TableBody>
                        <TableRow>
                            <TableCell>Name</TableCell>
                            <TableCell style={{textTransform:'capitalize'}}>{data.name}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>Fathers Name</TableCell>
                            <TableCell style={{textTransform:'capitalize'}}>{data.f_name}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>Educational Qualification</TableCell>
                            <TableCell style={{textTransform:'capitalize'}}>{data.edu_qualific}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>Password</TableCell>
                            <TableCell>
                            <Button variant="contained" color="secondary" size="small">Change Password</Button>
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>Account Status:</TableCell>
                            <TableCell style={{textTransform:'uppercase',fontWeight:'bold'}}>
                                {data.status}
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>Manage account</TableCell>
                            <TableCell>
                                <Button variant="contained" color="primary" size="small">Change Status</Button>
                            </TableCell>
                        </TableRow>

                        
                    </TableBody>
                </Table>
            </TableContainer>
        </Container>
    )
}