import logo from './logo.svg';
import './App.css';
import SignIn from './components/auth/login';
import Home from './components/Home';
import {BrowserRouter} from 'react-router-dom'
import React from 'react';
function App() {
  const [login,setLogin] = React.useState(true)
  React.useEffect(()=>{
    let authState = sessionStorage.getItem('authState')

    if(authState){
      setLogin(false)
    }
  },[])
  return (
    <div className="App">
      {/* <SignIn /> */}
      <BrowserRouter>
      {
        login ? <SignIn /> : <Home />
      }
      </BrowserRouter>
    </div>
  );
}

export default App;
