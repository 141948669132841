import { Avatar, Button, Container, Divider, Grid, IconButton, InputBase, LinearProgress, List, ListItem, ListItemAvatar, ListItemText, Menu, MenuItem, Paper, Typography } from "@mui/material";
import React from "react";
import { BsMarkdown, BsSearch } from "react-icons/bs";
import { Outlet } from "react-router";
import { Api,ImgApi } from "../../../../constant/api";
import {useNavigate} from 'react-router-dom'
import { ArrowRight, Check, Create, Delete, Edit } from "@mui/icons-material";

export default function EmployeeHome(){
    const navigate = useNavigate()
    const [data,setData] = React.useState([])
    const [loading,setLoading] = React.useState(false)

    React.useEffect(()=>{
        setLoading(true)
        fetch(Api+'employee_details')
        .then(res=>res.json())
        .then(res=>{
            setLoading(false)
            setData(res)
        })
        .catch(err=>{
           setLoading(false)
        })
  
    
    },[])


    return(

        <Container maxWidth="false" style={{padding:0}}>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={4}>
                <Paper style={{ padding: 10,display: 'flex',alignItems: 'center',marginBottom: 10}} variant="outlined">
                    <IconButton  aria-label="search">
                            <BsSearch />
                        </IconButton>
                    <InputBase
                        onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}
                        placeholder="Search by account no or name"
                        inputProps={{ 'aria-label': 'Search by account no or name' }}
                        // className={classess.input}
                        // onChange={handleChange}
                        style={{flex: 1}}
                        />
                        
                    </Paper>
                <Paper variant="outlined" style={{height: '76vh'}} className="App-test">
                        {loading ?  <LinearProgress /> : ''}
                        <List>
                            {
                                data.map((item,index)=>
                                <div key={index}>
                                    <ListItem >
                                        <ListItemAvatar>
                                        <Avatar>
                                        <img src={ImgApi+item.pass_img} width="100%" />
                                        </Avatar>
                                        </ListItemAvatar>
                                        <ListItemText
                                            primary={item.name}
                                            secondary={item.id}
                                        />
                                        
                                        <IconButton onClick={()=>navigate('/EmployeeDet/EmDet',{state : item})}  size="small">
                                            <ArrowRight />
                                        </IconButton>
                                    </ListItem>
                                    <Divider />
                                </div>
                                )
                            }
                        </List>
                    </Paper>
                </Grid>
                <Grid item xs={12} sm={8}>
                <Paper variant="outlined" style={{height : '100%', padding: 15}}>
                        <Outlet />
                </Paper>
                </Grid>
            </Grid>
        </Container>
    )
}