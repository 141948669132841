import { Container, TableContainer, Typography, Paper, TableHead, TableCell, TableBody, TableRow, Table } from "@mui/material";
import React from "react";
import { FiChrome } from "react-icons/fi";
import { Api } from "../../../../constant/api";




export default function Overdue(){
    const [data, setData] = React.useState([])
    const [coun_ac, setCountAc] = React.useState([])
    React.useEffect(()=>{
        
        fetch(Api+'overdue')
        .then(res=>res.json())
        .then(res=>{
            setData(res.main);
            setCountAc(res.count);
            
        })
        .catch(err=>{
           console.log(err)
        })
    },[])

    const count_emi = (ac_no)=>{
        let count = 0;
        coun_ac.map((item)=>{
            console.log(item)
            if(item.ac_no == ac_no){
                count = item.no
            }
        })

        return count

    }
    const total_due = (date,tenure,rec_emi,ac_no)=>{
        const today = new Date()
        // today.toLocaleDateString('ea-CA'

        const start_date = new Date(date)

        var months = today.getMonth() - start_date.getMonth() + (12 * (today.getFullYear() - start_date.getFullYear()));
        if(today.getDate() < start_date.getDate()){
            months--;
        } 
        

        if(months > tenure){
           months = tenure - count_emi(ac_no)
            
        }else{
           
            
        }


        
            return months
        
        
        
    }
    return(
        <Container>
            <Typography variant="h5">Overdue List</Typography>
            <hr />
            <TableContainer component={Paper} variant="outlined">
                <Table>
                    <TableHead>
                        <TableCell>Name</TableCell>
                        <TableCell>Last Rec. Date</TableCell>
                        <TableCell>Last Rec. For</TableCell>
                        <TableCell>Emi Amount</TableCell>
                        <TableCell>Total due</TableCell>
                        <TableCell>Total due amount</TableCell>
                    </TableHead>
                    <TableBody>
                        {
                            data.map((item,index)=>
                                <TableRow key={index}>
                                    <TableCell>{item.name}</TableCell>
                                    <TableCell>{item.rec_date}</TableCell>
                                    <TableCell>{item.emi_date}</TableCell>
                                    <TableCell>{(Number(item.princ_amount) + Number(item.int_amount)).toFixed(2)}</TableCell>
                                    <TableCell style={{color : 'red'}}>{total_due(item.emi_date != null ? item.emi_date : item.open_date ,item.tenure,item.rec_emi,item.emi_date != null ? item.ac_no : null)}</TableCell>
                                    <TableCell style={{color : 'red'}}>{(total_due(item.emi_date != null ? item.emi_date : item.open_date, item.tenure,item.rec_emi,item.emi_date != null ? item.ac_no : null ) * (Number(item.princ_amount) + Number(item.int_amount))).toFixed(2)}</TableCell>
                                </TableRow>
                            )
                        }
                    </TableBody>
                </Table>
            </TableContainer>
        </Container>
    )
}