import { Container, Typography, Button } from "@mui/material";
import AccountDet from '../../../../assets/selAccount.png'
import { Create } from "@mui/icons-material";
import {useNavigate} from 'react-router-dom'

export default function NoAccountSelected(){
    const navigate = useNavigate()

    const handleCreateAccount = ()=>{
        navigate('/AccountDetails/CreateLoan/')
    }

    return(
        <Container style={{height : '100%',display : 'flex', flexDirection : 'column',justifyContent : 'center', alignItems : 'center'}}>
            <img src={AccountDet} width={220} height={250} /> 
            <Typography>Please select an account to display here</Typography>
            <Typography>OR</Typography>
            <Button onClick={handleCreateAccount} variant="contained" color="success">
            <Create fontSize="16" />
                Add New Account
            </Button>           
        </Container>
    )
}