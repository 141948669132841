
import { Grid, Paper, TextField, Typography, Container, Box, Stepper, Step, StepLabel, useMediaQuery, useTheme, Toolbar, Button, FormControl, InputLabel, MenuItem, Select, CircularProgress, Checkbox } from "@mui/material";
import React from "react";
import { BiLeftArrow, BiRightArrow } from "react-icons/bi";
import FileResizer from 'react-image-file-resizer'
import { Api } from "../../../constant/api";
import SnakBar from "../../../constant/message";

const steps = ["Personal Details", "Documents", "Loan Details", "Vehicle Info"]
const week = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"]


export default function CreateLoan() {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.up('md'));
    const isStepFailed = (step) => {
        return null;
    };
    const [formData, setFormData] = React.useState({
        v_name: '',
        e_no: '',
        c_no: ''
    })
    const [err, setErr] = React.useState({})
    const [activeStep, setActiveStep] = React.useState(0)
    const [loading, setLoading] = React.useState(false)
    const [massg, setMassg] = React.useState({})
    const [agent, setAgent] = React.useState([])
    const [isCalculated, setisCalculated] = React.useState(false)
    const [checked, setChecked] = React.useState(true)

    React.useEffect(() => {
        setLoading(true)
        fetch(Api + 'employee_details')
            .then(res => res.json())
            .then(res => {

                setLoading(false)
                setAgent(res)
            })
            .catch(err => {
                setLoading(false)
                setMassg({
                    open: true,
                    severity: 'error',
                    massg: 'Faild to connect to the server'
                })
            })

    }, [])
    const handleChange = (e) => {

        if (e.target.name === 'pass_img') {
            let files = e.target.files

            try {
                FileResizer.imageFileResizer(
                    files[0],
                    800,
                    1000,
                    "PNG",
                    80,
                    0,
                    (uri) => {
                        setFormData({
                            ...formData,
                            [e.target.name]: uri
                        })
                    },
                    "file",
                    200,
                    200
                );


            } catch (err) {
                console.log(err);
            }

        } else {
            setFormData({
                ...formData,
                [e.target.name]: e.target.value
            })


        }

    }

    const handleStep = (val) => {

        if (val === 'next') {
            setActiveStep(activeStep + 1)
        }
        else {
            if (activeStep > 0) {
                setActiveStep(activeStep - 1)
            }
        }
    }


    const validate = () => {

        let valid = true
        let err = {}

        if (!formData.name) {
            valid = false
            err['name'] = true
        }
        if (!formData.ps) {
            valid = false
            err['ps'] = true
        }
        if (!formData.f_name) {
            valid = false
            err['f_name'] = true
        }
        if (!formData.po) {
            valid = false
            err['po'] = true
        }
        if (!formData.vill) {
            valid = false
            err['vill'] = true
        }
        if (!formData.pin) {
            valid = false
            err['pin'] = true
        }
        if (!formData.open_date) {
            valid = false
            err['open_date'] = true
        }

        if (!formData.ln_amnt) {
            valid = false
            err['ln_amnt'] = true
        }
        if (!formData.tot_int) {
            valid = false
            err['open_date'] = true
        }
        if (!formData.int_rt) {
            valid = false
            err['int_rt'] = true
        }

        if (!formData.tot_int) {
            valid = false
            err['tot_int'] = true
        }
        if (!formData.d_charge) {
            valid = false
            err['d_charge'] = true
        }
        if (!formData.v_name) {
            valid = false
            err['v_name'] = true
        }
        if (!formData.e_no) {
            valid = false
            err['e_no'] = true
        }
        if (!formData.c_no) {
            valid = false
            err['c_no'] = true
        }
        if (!formData.r_no) {
            valid = false
            err['r_no'] = true
        }
        if (!formData.d_charge) {
            valid = false
            err['d_charge'] = true
        }
        if (!formData.agent) {
            valid = false
            err['agent'] = true
        }
        if (!formData.tenure) {
            valid = false
            err['tenure'] = true
        }
        if (!formData.p_no) {
            valid = false
            err['p_no'] = true
        }
        if (!formData.v_amount) {
            valid = false
            err['v_amount'] = true
        }
        if (!formData.d_payment) {
            valid = false
            err['d_payment'] = true
        }
        if (!formData.t_r_amount) {
            valid = false
            err['t_r_amount'] = true
        }
        if (!formData.f_c) {
            valid = false
            err['f_c'] = true
        }
        if (!formData.emi_princ_amount) {
            valid = false
            err['emi_princ_amount'] = true
        }
        if (!formData.emi_int_amount) {
            valid = false
            err['emi_int_amount'] = true
        }
        if (!formData.last_date) {
            valid = false
            err['last_date'] = true
        }
        if (!formData.prop_date) {
            valid = false
            err['prop_date'] = true
        }


        setErr(err)
        console.log(err)

        return valid

    }


    const handleSubmit = () => {

        if (validate()) {

            const data = new FormData()
            Object.keys(formData).map((item, index) => {
                data.append(item, formData[item])
            })

            setLoading(true)
            fetch(Api + 'create_account', {
                method: 'POST',
                body: data
            })
                .then(res => res.json())
                .then(res => {
                    setLoading(false)
                    if (res.code === 200) {
                        setFormData({})
                        setActiveStep(0)
                        setMassg({
                            open: true,
                            severity: 'success',
                            massg: res.massg
                        })
                    } else {
                        setMassg({
                            open: true,
                            severity: 'error',
                            massg: res.massg
                        })
                    }
                })
                .catch(err => {
                    setLoading(false)
                    setMassg({
                        open: true,
                        severity: 'error',
                        massg: 'Faild to connect to the server'
                    })
                })

        } else {

            console.log(err)
        }
    }

    const handleMaths = () => {
        var v_amount = formData.v_amount
        var d_p = formData.d_payment
        var finance_amount = v_amount - d_p
        var d_c = formData.d_charge
        var interest_amount = (Number(finance_amount) + Number(d_c)) * (formData.int_rt / 100)
        var int_with_tenure = interest_amount * (formData.tenure / 12)
        var f_c = (Number(finance_amount) + Number(int_with_tenure) + Number(d_c)) * (10 / 100)
        var final_recoverable_amount = Number(finance_amount) + Number(d_c) + Number(int_with_tenure) + Number(f_c)

        if (!final_recoverable_amount === false) {
            setFormData({
                ...formData,
                t_r_amount: final_recoverable_amount,
                f_c: f_c,
                tot_int: int_with_tenure,
                emi_int_amount: Number((Number(final_recoverable_amount) - Number(finance_amount)) / (formData.tenure)).toFixed(2),
                emi_princ_amount: Number(Number(finance_amount) / (formData.tenure)).toFixed(2),
                ln_amnt: finance_amount
            })
        }


        setisCalculated(true)
    }

    const persional_info = (
        <Grid container spacing={2} style={{ padding: 10 }}>
            <Grid item xs={12} sm={4}>
                <TextField
                    variant="outlined"
                    fullWidth
                    size="small"
                    style={{ margin: 5 }}
                    label="Name"
                    required
                    name="name"
                    value={formData.name}
                    error={err.name}
                    onChange={handleChange}

                />
                <TextField
                    variant="outlined"
                    fullWidth
                    size="small"
                    style={{ margin: 5 }}
                    label="PS"
                    required
                    name="ps"
                    value={formData.ps}
                    error={err.ps}
                    onChange={handleChange}

                />
                <TextField
                    variant="outlined"
                    fullWidth
                    size="small"
                    style={{ margin: 5 }}
                    label="Contact No"
                    name="ph_no"
                    value={formData.ph_no}
                    error={err.ph_no}
                    onChange={handleChange}

                />
                <TextField
                    variant="outlined"
                    fullWidth
                    size="small"
                    style={{ margin: 5 }}
                    label="Guarantor Name"

                    name="g_name"
                    value={formData.g_name}
                    onChange={handleChange}
                />
                <TextField
                    variant="outlined"
                    fullWidth
                    size="small"
                    style={{ margin: 5 }}
                    label="Guarantor PS"

                    name="g_ps"
                    value={formData.g_ps}
                    error={err.g_ps}
                    onChange={handleChange}
                />
            </Grid>
            <Grid item xs={12} sm={4}>
                <TextField
                    variant="outlined"
                    fullWidth
                    size="small"
                    style={{ margin: 5 }}
                    label="F/H Name"
                    required
                    name="f_name"
                    value={formData.f_name}
                    error={err.f_name}
                    onChange={handleChange}

                />
                <TextField
                    variant="outlined"
                    fullWidth
                    size="small"
                    style={{ margin: 5 }}
                    label="PO"
                    required
                    name="po"
                    value={formData.po}
                    error={err.po}
                    onChange={handleChange}

                />
                <TextField
                    variant="outlined"
                    fullWidth
                    size="small"
                    style={{ margin: 5 }}
                    label="District"
                    name="district"
                    value={formData.district}
                    error={err.district}
                    onChange={handleChange}

                />
                <TextField
                    variant="outlined"
                    fullWidth
                    size="small"
                    style={{ margin: 5 }}
                    label="Guarantor F/H Name"

                    name="g_f_name"
                    value={formData.g_f_name}
                    error={err.g_f_name}
                    onChange={handleChange}
                />
                <TextField
                    variant="outlined"
                    fullWidth
                    size="small"
                    style={{ margin: 5 }}
                    label="Guarantor PO"

                    name="g_po"
                    value={formData.g_po}
                    error={err.g_po}
                    onChange={handleChange}
                />
                 
            </Grid>
            <Grid item xs={12} sm={4}>
                <TextField
                    variant="outlined"
                    fullWidth
                    size="small"
                    style={{ margin: 5 }}
                    label="Vill"
                    required
                    name="vill"
                    value={formData.vill}
                    error={err.vill}
                    onChange={handleChange}
                />
                <TextField
                    variant="outlined"
                    fullWidth
                    size="small"
                    style={{ margin: 5 }}
                    label="Pin"
                    required
                    name="pin"
                    value={formData.pin}
                    error={err.pin}
                    onChange={handleChange}
                />

                <TextField
                    variant="outlined"
                    fullWidth
                    size="small"
                    style={{ margin: 5 }}
                    label="Guarantor Vill"

                    name="g_vill"
                    value={formData.g_vill}
                    error={err.g_vill}
                    onChange={handleChange}
                />
                <TextField
                    variant="outlined"
                    fullWidth
                    size="small"
                    style={{ margin: 5 }}
                    label="Guarantor Pin"

                    name="g_pin"
                    value={formData.g_pin}
                    error={err.g_pin}
                    onChange={handleChange}
                />
                <TextField
                    variant="outlined"
                    fullWidth
                    size="small"
                    style={{ margin: 5 }}
                    label="Guarantor Contact No."
                    name="g_contact"
                    value={formData.g_contact}
                    onChange={handleChange}
                />
            </Grid>
            <Grid item xs={12} sm={4}>
                <FormControl required variant="outlined" style={{ margin: 5 }} size="small" fullWidth>
                    <InputLabel id="demo-simple-select-outlined-label">Select Agent</InputLabel>
                    <Select
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        error={err.agent}
                        value={formData.agent}
                        onChange={handleChange}
                        label="agent"
                        name="agent"
                    >
                        <MenuItem value={false}>
                            <em>None</em>
                        </MenuItem>
                        {
                            agent.map((item, index) =>
                                <MenuItem key={index} value={item.id}>{item.name}</MenuItem>
                            )
                        }
                    </Select>
                </FormControl>
            </Grid>
        </Grid>
    )

    const documents = (
        <Grid container spacing={2}>
            <Grid item xs={12} sm={4}>
                <TextField
                    variant="outlined"
                    fullWidth
                    size="small"
                    style={{ margin: 5 }}
                    helperText="Passport Image"
                    type="file"
                    name="pass_img"

                    error={err.pass_img}
                    onChange={handleChange}
                />
            </Grid>
        </Grid>
    )

    const loan_details = (
        <Grid container spacing={2} style={{ padding: 10 }}>
            <Grid item xs={12} sm={4}>
                <TextField
                    variant="outlined"
                    fullWidth
                    size="small"
                    style={{ margin: 5 }}
                    helperText="Proposal Date"
                    type="date"
                    required
                    name="prop_date"
                    value={formData.prop_date}
                    error={err.prop_date}
                    onChange={handleChange}
                />
                <TextField
                    variant="outlined"
                    fullWidth
                    size="small"
                    style={{ margin: 5 }}
                    label="Loan Amount"
                    type="number"
                    required
                    name="ln_amnt"
                    value={formData.ln_amnt}
                    error={err.ln_amnt}
                    onChange={handleChange}
                    disabled={!isCalculated}
                    focused={isCalculated}
                />
                <TextField
                    variant="outlined"
                    fullWidth
                    size="small"
                    style={{ margin: 5 }}
                    label="Tenure(months)"
                    type="number"
                    required
                    name="tenure"
                    value={formData.tenure}
                    error={err.tenure}
                    onChange={handleChange}

                />
                <TextField
                    variant="outlined"
                    fullWidth
                    size="small"
                    style={{ margin: 5 }}
                    label="EMI Interest+fc+dc"
                    type="number"
                    required
                    name="emi_int_amount"
                    value={formData.emi_int_amount}
                    error={err.emi_int_amount}
                    onChange={handleChange}
                    disabled={!isCalculated}
                    focused={isCalculated}
                />
                <TextField
                    variant="outlined"
                    fullWidth
                    size="small"
                    style={{ margin: 5 }}
                    label="Proposal No"
                    type="number"
                    required
                    name="p_no"
                    value={formData.p_no}
                    error={err.p_no}
                    onChange={handleChange}
                />
            </Grid>
            <Grid item xs={12} sm={4}>

                <TextField
                    variant="outlined"
                    fullWidth
                    size="small"
                    style={{ margin: 5 }}
                    label="Vehicle Amount"
                    type="number"
                    required
                    name="v_amount"
                    value={formData.v_amount}
                    error={err.v_amount}
                    onChange={handleChange}
                />
                <TextField
                    variant="outlined"
                    fullWidth
                    size="small"
                    style={{ margin: 5 }}
                    label="Document Charge"
                    required
                    name="d_charge"
                    value={formData.d_charge}
                    error={err.d_charge}
                    onChange={handleChange}
                />
                <TextField
                    variant="outlined"
                    fullWidth
                    size="small"
                    style={{ margin: 5 }}
                    label="Finance Commision"
                    required
                    name="f_c"
                    value={formData.f_c}
                    error={err.f_c}
                    onChange={handleChange}
                    type="number"
                    disabled={!isCalculated}
                    focused={isCalculated}
                />
                <TextField
                    variant="outlined"
                    fullWidth
                    size="small"
                    style={{ margin: 5 }}
                    label="Total Interest Amount"
                    type="number"
                    required
                    name="tot_int"
                    value={formData.tot_int}
                    error={err.tot_int}
                    onChange={handleChange}
                    disabled={!isCalculated}
                    focused={isCalculated}
                />

                <TextField
                    variant="outlined"
                    fullWidth
                    size="small"
                    style={{ margin: 5 }}
                    helperText="Emi starting Date"
                    type="date"
                    required
                    name="open_date"
                    value={formData.open_date}
                    error={err.open_date}
                    onChange={handleChange}
                />


            </Grid>
            <Grid item xs={12} sm={4}>
                <TextField
                    variant="outlined"
                    fullWidth
                    size="small"
                    style={{ margin: 5 }}
                    label="Down Payment"
                    type="number"
                    required
                    name="d_payment"
                    value={formData.d_payment}
                    error={err.d_payment}
                    onChange={handleChange}
                />
                <TextField
                    variant="outlined"
                    fullWidth
                    size="small"
                    style={{ margin: 5 }}
                    label="Interest Rate"
                    type="number"
                    helperText="Please don't add % symbol"
                    required
                    name="int_rt"
                    value={formData.int_rt}
                    error={err.int_rt}
                    onChange={handleChange}
                />
                <TextField
                    variant="outlined"
                    fullWidth
                    size="small"
                    style={{ margin: 5 }}
                    label="Total recoverable amount"
                    type="number"
                    required
                    name="t_r_amount"
                    value={formData.t_r_amount}
                    error={err.t_r_amount}
                    onChange={handleChange}
                    disabled={!isCalculated}
                    focused={isCalculated}
                />
                <TextField
                    variant="outlined"
                    fullWidth
                    size="small"
                    style={{ margin: 5 }}
                    label="EMI principle amount"
                    type="number"
                    required
                    name="emi_princ_amount"
                    value={formData.emi_princ_amount}
                    error={err.emi_princ_amount}
                    onChange={handleChange}
                    disabled={!isCalculated}
                    focused={isCalculated}
                />
                <TextField
                    variant="outlined"
                    fullWidth
                    size="small"
                    style={{ margin: 5 }}
                    helperText="EMI  amount"
                    type="number"
                    required
                    name="emi_princ_amount"
                    value={Number(formData.emi_princ_amount) + Number(formData.emi_int_amount)}
                    error={err.emi_princ_amount}

                    disabled
                    focused={isCalculated}
                />
                <TextField
                    variant="outlined"
                    fullWidth
                    size="small"
                    style={{ margin: 5 }}
                    helperText="Last EMI"
                    type="date"
                    required
                    name="last_date"
                    value={formData.last_date}
                    error={err.last_date}
                    onChange={handleChange}
                />



            </Grid>
            <Toolbar style={{ justifyContent: 'flex-end', width: '100%' }}>
                <Button color="error" variant="contained" onClick={handleMaths}>calculate</Button>
            </Toolbar>

        </Grid>
    )

    const vehicle = (
        <Grid container spacing={2} style={{ padding: 10 }}>
            <Grid item xs={12} sm={4}>
                <TextField
                    variant="outlined"
                    fullWidth
                    size="small"
                    style={{ margin: 5 }}
                    label="Vehicle Name"
                    type="text"
                    required
                    name="v_name"
                    value={formData.v_name}
                    error={err.v_name}
                    onChange={handleChange}
                />
            </Grid>
            <Grid item xs={12} sm={4}>
                <TextField
                    variant="outlined"
                    fullWidth
                    size="small"
                    style={{ margin: 5 }}
                    label="Engine No"
                    type="text"
                    required
                    name="e_no"
                    value={formData.e_no}
                    error={err.e_no}
                    onChange={handleChange}
                />
            </Grid>
            <Grid item xs={12} sm={4}>
                <TextField
                    variant="outlined"
                    fullWidth
                    size="small"
                    style={{ margin: 5 }}
                    label="Chassis No"
                    type="text"
                    required
                    name="c_no"
                    value={formData.c_no}
                    error={err.c_no}
                    onChange={handleChange}
                />
            </Grid>
            <Grid item xs={12} sm={4}>
                <TextField
                    variant="outlined"
                    fullWidth
                    size="small"
                    style={{ margin: 5 }}
                    label="Registration No"
                    type="text"
                    required
                    name="r_no"
                    value={formData.r_no}
                    error={err.r_no}
                    onChange={handleChange}
                />
            </Grid>
        </Grid>
    )

    return (
        <Container maxWidth="false" style={{ padding: 0 }}>
            <Toolbar component={Paper}>
                <Typography variant="h6" style={{ fontWeight: 'bold' }}>
                    Create New Loan
                </Typography>
            </Toolbar>
            <Paper variant="outlined" style={{ padding: isMobile ? 30 : 15, display: 'flex', marginBottom: 10 }}>
                <Box sx={{ width: '100%' }}>
                    <Stepper activeStep={activeStep} alternativeLabel>
                        {steps.map((label, index) => {
                            const labelProps = {};
                            if (isStepFailed(index)) {
                                labelProps.optional = (
                                    <Typography variant="caption" color="error">
                                        Alert message
                                    </Typography>
                                );

                                labelProps.error = true;
                            }

                            return (
                                <Step key={label}>
                                    <StepLabel {...labelProps}>{label}</StepLabel>
                                </Step>
                            );
                        })}
                    </Stepper>
                </Box>
                {/* <Typography style={{ margin: '10px' }}>
                            <Checkbox onChange={() => setChecked(!checked)} checked={checked} />
                            Send Text Notification
                        </Typography> */}
            </Paper>

            <Paper variant="outlined" style={{ marginTop: 10 }}>
                {activeStep === 0 ? persional_info : activeStep === 1 ? documents : activeStep === 2 ? loan_details : activeStep === 3 ? vehicle : ''}
                <Toolbar style={{ justifyContent: 'flex-end' }}>
                    <Button onClick={() => handleStep('prev')} variant="contained" color="info">
                        <BiLeftArrow />
                        Previous
                    </Button>
                    {
                        activeStep === 3 ? (
                            <Button disabled={loading} onClick={() => handleSubmit()} variant="contained" color='primary' style={{ marginLeft: 10 }}>
                                <BiRightArrow />
                                Submit
                                {loading ? <CircularProgress size={20} color="inherit" /> : ''}
                            </Button>
                        ) : (
                            <Button onClick={() => handleStep('next')} variant="contained" color='primary' style={{ marginLeft: 10 }}>
                                Next
                                <BiRightArrow />
                            </Button>
                        )
                    }
                </Toolbar>

            </Paper>
            <SnakBar massg={massg} setMassg={setMassg} />
        </Container>
    )
}