import { Typography } from "@mui/material";
import React from "react";




export default function SignOut(){

    React.useEffect(()=>{
        sessionStorage.clear();
        setInterval(()=>{
            window.location.replace('/')
        },2000)
    },[])
    return(
        <Typography variant="h5">Please wait..................</Typography>
    )
}