import { Container, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Toolbar, IconButton, Tooltip, Typography, Dialog, DialogTitle, DialogContent, TextField, Button, CircularProgress } from "@mui/material";
import React from "react";
import { useLocation } from "react-router-dom";
import { Api } from "../../../../constant/api";
import color from "../../../../constant/colors";
import SnakBar from "../../../../constant/message";
import {BsPrinterFill, BsTable} from 'react-icons/bs'
import {MdDelete} from 'react-icons/md'
import { Box } from "@mui/system";

export default function LadgerBook(){
    const location = useLocation()
    let id = location.state
    const [data, setData] = React.useState([])
    const [loanin, setLoanin] = React.useState({})
    const [detailScreen, setDetailScreen] = React.useState(false)
    const [massg,setMassg] = React.useState({})
    const [dialogView, setDialogView] = React.useState(false)
    const [fines, setFines] = React.useState([])
    const [deleteT,setDelete] = React.useState({
        open : false,
        data : '',
        loading: false,
        id : null,
        ac_no : id,
        user : 'not available'
    })

    const collect_data = ()=>{
        if(!id === false){
            fetch(Api+'ladger',{
                method : 'POST',
                body : JSON.stringify({
                  id : id
                })
            })
            .then(res=>res.json())
            .then(res=>{
                setData(res.emi)
                setLoanin(res.loan[0])
            })
            .catch(err=>{
               console.log(err)
            })
    
    
            }
    }
  
    React.useEffect(()=>{

        collect_data()
    },[location])


    let princ_bal = 0
    let intr_bal = 0

    const handleDelete = (id)=>{
        setDelete({
            ...deleteT,
            open : true,
            id : id,
            loading : false,
            
        })
    }

    const finalDelete = ()=>{
        setDelete({
            ...deleteT,
            loading : true
        })
       
        if(!deleteT.data){
            setMassg({
                open : true,
                severity : 'error',
                massg : "Please describe your reason"
            })
        }else{
            fetch(Api+'deleteEMI',{
                method : 'POST',
                body : JSON.stringify(deleteT)
              })
              .then(res=>res.json())
              .then(res=>{
                  setDelete({
                      ...deleteT,
                      loading : false,
                      open : false
                  })
                  console.log(res)
                if(res.code === 200){
                    collect_data()
                  setMassg({
                    open : true,
                    severity : 'success',
                    massg : res.massg
                  })
                }else{
                  setMassg({
                    open : true,
                    severity : 'error',
                    massg : res.massg
                  })
                }
              })
              .catch(err=>{
                  setDelete({
                      ...deleteT,
                      loading : false
                  })
                setMassg({
                  open : true,
                  severity : 'error',
                  massg : 'Faild to connect to the server'
                })
              })
        }
    }

    const handleChangeDes = (e)=>{
        setDelete({...deleteT,data : e.target.value})
    }

    const handleDetailScreen = ()=>{
        if(sessionStorage.getItem('role') === 'admin'){
            setDetailScreen(!detailScreen)
        }else{
            setMassg({
                open : true,
                severity : 'error',
                massg : "You don't have permission to view"
              })
        }
    }

    const handleFine = ()=>{
        setDialogView(true)
        fetch(Api+'balanceAndFine',{
            method : 'POST',
            body : JSON.stringify({
                id : id
            })
        })
        .then(res=>res.json())
        .then(res=>{
            setFines(res)

        })
        .catch(err=>{
           console.log(err)
        })

    }
    return(
        <Container>
            <Box style={{display : 'flex',justifyContent: 'space-between',marginBottom: 5,padding: 5, alignItems : 'center'}} component={Paper} variant="outlined"  >
                <div>
                    <Typography variant="h5">Name: {loanin.name}</Typography>
                    <Typography>AC NO: {id}</Typography>
                </div>
                <Toolbar>
                <Button size="small" color="primary" variant="contained" onClick={handleFine}>
                    Fine & Balance
                </Button>
                <Tooltip title="Print Ladger">
                <IconButton size="small">
                    <BsPrinterFill />
                </IconButton>
                </Tooltip>
                <Tooltip title="More detail">
                    <IconButton onClick={()=>handleDetailScreen()} size="small">
                        <BsTable />
                    </IconButton>
                </Tooltip>
                
                </Toolbar>

            </Box>
        {
            detailScreen ? (
                <TableContainer component={Paper} variant="outlined" >
                    <Table size="small">
                        <TableHead>
                            <TableCell>Del</TableCell>
                            <TableCell>SL No</TableCell>
                            <TableCell>Recipt No</TableCell>
                            <TableCell>Recived Date</TableCell>
                            <TableCell>Recived At</TableCell>
                            <TableCell>Emi Amount</TableCell>
                            
                            <TableCell colSpan={3}>Recived </TableCell>
                            <TableCell>Fine</TableCell>
                            <TableCell colSpan={2}>Balance</TableCell>
                            <TableCell>Total</TableCell>
                            <TableCell>Remarks</TableCell>
                            <TableCell>Recived By</TableCell>
                        </TableHead>
                        <TableHead>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell>Total</TableCell>
                            <TableCell>Principle </TableCell>
                            <TableCell>Interest </TableCell>
                            <TableCell></TableCell>
                            <TableCell>Principle </TableCell>
                            <TableCell>Interest </TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            
                        </TableHead>
                        <TableBody>
                        {
                            data.map((item,index)=>
                                {
                                    princ_bal += Number(item.princ)
                                    intr_bal += Number(item.intr)
                                    return (
                                        <TableRow key={index}>
                                            <TableCell>
                                                <IconButton onClick={()=>handleDelete(item.id)} size="small">
                                                    <MdDelete />
                                                </IconButton>
                                            </TableCell>
                                            <TableCell>{index + 1}</TableCell>
                                            <TableCell>{item.r_no}</TableCell>
                                            <TableCell>{item.rec_date}</TableCell>
                                            <TableCell>{item.rec_at}</TableCell>
                                            <TableCell>{(Number(loanin.princ_amount) + Number(loanin.int_amount)).toFixed(2)}</TableCell>
                                            <TableCell>{(Number(item.princ) + Number(item.intr)).toFixed(2) }</TableCell>
                                            
                                            <TableCell>{Number(item.princ).toFixed(2)}</TableCell>
                                            <TableCell>{item.intr}</TableCell>
                                            <TableCell>{item.fine}</TableCell>
                                            <TableCell>{(loanin.ln_amnt - princ_bal).toFixed(2)}</TableCell>
                                            <TableCell>{((Number(loanin.tot_int) + Number(loanin.fc) + Number(loanin.d_charge)) - intr_bal).toFixed(2)}</TableCell>
                                            <TableCell>{((Number(loanin.ln_amnt) + Number(loanin.tot_int) + Number(loanin.fc) + Number(loanin.d_charge)) - (Number(princ_bal)+Number(intr_bal))).toFixed(2) }</TableCell>
                                            <TableCell>{item.remark}</TableCell>
                                            <TableCell>{item.user}</TableCell>
                                        </TableRow>
                                    )
                                }
                            )
                        }
                        </TableBody>
                    </Table>
                </TableContainer>
            ) : (
                <TableContainer component={Paper} variant="outlined" >
                <Table size="small">
                    <TableHead>
                        <TableCell>SL No</TableCell>
                        <TableCell>Recipt No</TableCell>
                        <TableCell>Recived Date</TableCell>
                        <TableCell>Emi Amount</TableCell>
                        
                        <TableCell colSpan={3}>Recived </TableCell>
                        <TableCell>Fine</TableCell>
                        <TableCell colSpan={2}>Balance</TableCell>
                        <TableCell>Total</TableCell>
                        <TableCell>Remark</TableCell>
                    </TableHead>
                    <TableHead>
                        <TableCell></TableCell>
                        <TableCell></TableCell>
                        {/* <TableCell></TableCell> */}
                        <TableCell></TableCell>
                        <TableCell></TableCell>
                        <TableCell>Total</TableCell>
                        <TableCell>Principle </TableCell>
                        <TableCell>Interest </TableCell>
                        <TableCell></TableCell>
                        <TableCell>Principle </TableCell>
                        <TableCell>Interest </TableCell>
                        <TableCell></TableCell>
                        <TableCell></TableCell>
                        
                    </TableHead>
                    <TableBody>
                    {
                        data.map((item,index)=>
                            {
                                princ_bal += Number(item.princ)
                                intr_bal += Number(item.intr)
                                return (
                                    <TableRow key={index}>
                                        <TableCell>{index + 1}</TableCell>
                                        <TableCell>{item.r_no}</TableCell>
                                        <TableCell>{item.rec_date}</TableCell>
                                        <TableCell>{(Number(loanin.princ_amount) + Number(loanin.int_amount)).toFixed(2)}</TableCell>
                                        <TableCell>{(Number(item.princ) + Number(item.intr)).toFixed(2) }</TableCell>
                                        
                                        <TableCell>{Number(item.princ).toFixed(2)}</TableCell>
                                        <TableCell>{item.intr}</TableCell>
                                        <TableCell>{item.fine}</TableCell>
                                        <TableCell>{(loanin.ln_amnt - princ_bal).toFixed(2)}</TableCell>
                                        <TableCell>{((Number(loanin.tot_int) + Number(loanin.fc) + Number(loanin.d_charge)) - intr_bal).toFixed(2)}</TableCell>
                                        <TableCell>{((Number(loanin.ln_amnt) + Number(loanin.tot_int) + Number(loanin.fc) + Number(loanin.d_charge)) - (Number(princ_bal)+Number(intr_bal))).toFixed(2) }</TableCell>
                                        <TableCell>{item.remark}</TableCell>
                                    </TableRow>
                                )
                            }
                        )
                    }
                    </TableBody>
                </Table>
            </TableContainer>
            )
        }

        <Dialog
            open={deleteT.open}
            handleClose={()=>setDelete({...deleteT,open : false})}
        >
            <DialogTitle>
                Specify your reason
            </DialogTitle>
            <DialogContent>
                <TextField
                    label="reason"
                    required
                    value={deleteT.data}
                    onChange={handleChangeDes}
                    fullWidth
                    style={{
                        marginTop: 10,
                        marginBottom: 10
                    }}
                    multiline
                />
                <Button
                    variant="contained"
                    color="primary"
                    onClick={finalDelete}
                >
                    Submit
                    {
                        deleteT.loading ? <CircularProgress /> : ''
                    }
                </Button>
            </DialogContent>
        </Dialog>

        <Dialog
            open={dialogView}
            onClose={()=>setDialogView(false)}

        >
            <DialogTitle>Fine and balance</DialogTitle>
            <DialogContent>
                <TableContainer component={Paper} variant="outlined">
                    <Table size="small">
                        <TableHead>
                            <TableCell>Sl No</TableCell>
                            <TableCell>EMI Date</TableCell>
                            <TableCell>Rec Date</TableCell>
                            <TableCell>Fine Amount</TableCell>
                        </TableHead>
                        <TableBody>
                        {
                        fines.map((item,index)=>
                            <TableRow>
                                <TableCell>{index+1}</TableCell>
                                <TableCell>{item.emi_date}</TableCell>
                                <TableCell>{item.rec_date}</TableCell>
                                <TableCell>300</TableCell>
                            </TableRow>
                        )
                        }
                        <TableRow>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell>Total</TableCell>
                            <TableCell>{fines.length * 300}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell>Loan Balance</TableCell>
                            <TableCell>{((Number(loanin.ln_amnt) + Number(loanin.tot_int) + Number(loanin.fc) + Number(loanin.d_charge)) - (Number(princ_bal)+Number(intr_bal))).toFixed(2)}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell>Net Balance</TableCell>
                            <TableCell>{Number((Number(Number(loanin.ln_amnt) + Number(loanin.tot_int) + Number(loanin.fc) + Number(loanin.d_charge)) - (Number(princ_bal)+Number(intr_bal))).toFixed(2)) + Number(fines.length * 300)}</TableCell>
                        </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>
            </DialogContent>

        </Dialog>

        <SnakBar massg={massg} setMassg={setMassg} />
        </Container>
    )
}