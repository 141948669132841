import { ArrowRight, Check, Create, Delete, Edit } from "@mui/icons-material";
import { Avatar, Button, CircularProgress, Container, Divider, Grid, IconButton, InputBase, LinearProgress, List, ListItem, ListItemAvatar, ListItemText, Menu, MenuItem, Paper, Toolbar, Typography } from "@mui/material";
import React from "react";
import { BiBookContent,  } from "react-icons/bi";
import { BsMarkdown, BsSearch,BsInfoCircleFill } from "react-icons/bs";
import { Outlet } from "react-router";
import {MdPayment} from 'react-icons/md'

import { Api,ImgApi } from "../../../../constant/api";
import {useNavigate} from 'react-router-dom'
import SnakBar from "../../../../constant/message";
import color from "../../../../constant/colors";
export default function AccountDetails(){
    const navigate = useNavigate()
    const [data,setData] = React.useState([])
    const [selectedAccount, setSelectedAccount] = React.useState({})
    const [loading,setLoading] = React.useState(false)
    const [anchorE1, setAnchorE1] = React.useState(null)
    const menuOpen = Boolean(anchorE1)
    const [delLoading,setDelLoading] = React.useState(false)
    const [massg,setMassg] = React.useState({})
    const [refresh, setRefresh] = React.useState(Math.random())
    const [markLoading, setMarkLoading] = React.useState(false)
    const [status, setStatus] = React.useState(null)
    React.useEffect(()=>{

            setLoading(true)
            fetch(Api+'account_details')
            .then(res=>res.json())
            .then(res=>{
                setLoading(false)
                setData(res)
            })
            .catch(err=>{
               console.log(err)
            })

            
      
        
    },[refresh])


    const handleRoute = (e,r,i,status)=>{
        setSelectedAccount(i)
        if(r === 'AccountInformation'){
            setAnchorE1(e)
            setStatus(status)
            
        }else{
            if(r ==='CollectEmi'){
                if(status === 'active'){
                    navigate('/AccountDetails/'+r,{state : i})
                }else{
                    setMassg({
                        open : true,
                        severity : 'error',
                        massg : 'Account status is already completed'
                    })
                }
            }else{
                navigate('/AccountDetails/'+r,{state : i})
            }
            
        }
        
    }

    
    const handleSearch = (e)=>{
        setLoading(true)
            fetch(Api+'account_search',{
                method : 'POST',
                body : JSON.stringify({
                    query : e.target.value
                })
            })
            .then(res=>res.json())
            .then(res=>{
                setLoading(false)
                setData(res)
            })
            .catch(err=>{
               console.log(err)
            })
    }

    const handleDelete = ()=>{
       if(sessionStorage.getItem('role') === 'admin'){
        setDelLoading(true)
  
        fetch(Api+'delete_account',{
          method : 'POST',
          body : JSON.stringify({
            id : selectedAccount
          })
        })
        .then(res=>res.json())
        .then(res=>{
          setDelLoading(false)
          setRefresh(Math.random())
          setAnchorE1(null)
          if(res.code === 200){

            setMassg({
              open : true,
              severity : 'success',
              massg : res.massg
            })
          }else{
            setMassg({
              open : true,
              severity : 'error',
              massg : res.massg
            })
          }
        })
        .catch(err=>{
            console.log(err)
            setDelLoading(false)
            setMassg({
                open : true,
                severity : 'error',
                massg : 'Faild to connect to the server'
            })
        })
       }else{
        setMassg({
            open : true,
            severity : 'error',
            massg : "You don't have permission"
        })
        setAnchorE1(null)
       }
    }

    const markasComplete = ()=>{
        setMarkLoading(true)
        fetch(Api+'markasComplete',{
          method : 'POST',
          body : JSON.stringify({
            id : selectedAccount,
            status : status === 'active' ? 'completed' : 'active'
          })
        })
        .then(res=>res.json())
        .then(res=>{
          setMarkLoading(false)
          setAnchorE1(null)
          if(res.code === 200){
            setRefresh(Math.random())
            setMassg({
              open : true,
              severity : 'success',
              massg : res.massg
            })
          }else{
            setMassg({
              open : true,
              severity : 'error',
              massg : res.massg
            })
          }
        })
        .catch(err=>{
          setMarkLoading(false)
          setMassg({
            open : true,
            severity : 'error',
            massg : 'Faild to connect to the server'
          })
        })
    }

    return(
        <Container maxWidth="false" style={{padding:0}}>
            <Toolbar component={Paper} style={{display:'flex',justifyContent:'center',marginBottom:10}}>
                <Typography variant="h6" style={{fontWeight:'bold'}}>
                    Account Details
                </Typography>
            </Toolbar>
            <Grid container spacing={2}>
                <Grid item  xs={12} sm={4}>
                <Paper style={{ padding: 10,display: 'flex',alignItems: 'center',marginBottom: 10}} variant="outlined">
                <IconButton  aria-label="search">
                         <BsSearch />
                    </IconButton>
                <InputBase
                    onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}
                    placeholder="Search by proposal no or name or registration number"
                    inputProps={{ 'aria-label': 'Search by account no or name' }}
                    // className={classess.input}
                    onChange={handleSearch}
                    style={{flex: 1}}
                    />
                    
                </Paper>
                <Paper variant="outlined" style={{height: '76vh'}} className="App-test">
                    {loading ?  <LinearProgress /> : ''}
                    <List>
                        {
                            data.map((item,index)=>
                               <div key={index}>
                                <ListItem style={{backgroundColor : item.status === 'completed' ? color.secondary_light : '#fff'}}  >
                                    <ListItemAvatar>
                                       <Avatar>
                                       <img src={ImgApi+item.pass_img} width="100%" />
                                       </Avatar>
                                    </ListItemAvatar>
                                    <ListItemText
                                        primary={item.name}
                                        secondary={
                                            <div style={{display : 'flex'}}>
                                                {item.id}/<div style={{color: 'red'}}>{item.proposal_no}</div>
                                            </div>
                                        }
                                    />
                                    <IconButton onClick={()=>handleRoute(null,'CollectEmi',item.id,item.status)} size="small">
                                        <MdPayment />
                                    </IconButton>
                                    <IconButton onClick={()=>handleRoute(null,'LadgerBook',item.id)} size="small">
                                        <BiBookContent />
                                    </IconButton>
                                    <IconButton onClick={({currentTarget : e})=>handleRoute(e,'AccountInformation',item.id,item.status)} size="small">
                                        <ArrowRight />
                                    </IconButton>
                                </ListItem>
                                <Divider />
                               </div>
                            )
                        }
                    </List>
                </Paper>
                </Grid>
                <Grid item xs={12} sm={8}>
                    <Paper variant="outlined" style={{height : '100%', padding: 10}}>
                        
                        <Outlet />
                    </Paper>
                </Grid>
            </Grid>

        <Menu
            anchorEl={anchorE1}
            open={menuOpen}
            onClose={()=>setAnchorE1(null)}
        >
        <MenuItem onClick={()=>handleDelete()}>
            <Delete /> 
            <Typography style={{marginLeft: 5}}  >Delete Account</Typography>
            {delLoading ? <CircularProgress /> : ''}
        </MenuItem> 
        <MenuItem onClick={()=>{navigate('/AccountDetails/EditLoan',{state : selectedAccount});setAnchorE1(null)}}>
            <Edit />
            <Typography style={{marginLeft: 5}}>Edit Account</Typography>
        </MenuItem>
        <MenuItem onClick={markasComplete}>
            <Check />
            <Typography style={{marginLeft: 5}}>Mark as {status === 'active' ? 'Complete' : 'Active' } </Typography>
            {markLoading ? <CircularProgress /> : ''}
        </MenuItem> 
        <MenuItem onClick={()=>{navigate('/AccountDetails/AccountInformation',{state : selectedAccount});setAnchorE1(null)}}>
            <BsInfoCircleFill />
            <Typography style={{marginLeft: 5}}>Account Details</Typography>
        </MenuItem> 
        </Menu>
        <SnakBar massg={massg} setMassg={setMassg} />
        </Container>
    )
}