import { Divider, Grid, Paper, Table, TableBody, TableCell, TableContainer, TableRow, TextField, Toolbar, Typography } from "@mui/material";
import { Container } from "@mui/system";
import React from "react";
import { Api } from "../../../../constant/api";

export default function ProfitLoss() {
    const [data, setData] = React.useState({
        emi: [{ princ: 0, intr: 0 }],
        loan: [{ ln_amnt: 0 }]
    })
    const today = new Date()
    const next = today.setDate(today.getDate() + 7);
    const [date, setDate] = React.useState({
        to: new Date().toLocaleDateString("en-CA"),
        from: new Date(next).toLocaleDateString("en-CA"),
    })

    const handleDate = (event) => {
        let name = event.target.name;
        let value = event.target.value;

        setDate({
            ...date,
            [name]: value,

        })
    }
    React.useEffect(() => {
        fetch(Api + 'cashBook', {
            method: 'POST',
            body: JSON.stringify(date)
        })
            .then(res => res.json())
            .then(res => setData(res))
            .catch(err => {
                console.log(err)
            })
    }, [date])

    return (
        <Container maxWidth="false" style={{ padding: 0 }}>
            <Toolbar component={Paper} style={{ display: 'flex', justifyContent: 'space-between', marginBottom: 10 }} >
                <Typography variant="h6" style={{ fontWeight: 'bold' }}>
                    Profit And Loss
                </Typography>
                <div>
                    <TextField
                        type="date"
                        variant="outlined"
                        size="small"
                        name="to"
                        value={date.to}
                        onChange={handleDate}
                    />
                    <TextField
                        type="date"
                        variant="outlined"
                        size="small"
                        name="from"
                        style={{ marginLeft: 10, }}
                        value={date.from}
                        onChange={handleDate}
                    />
                </div>
            </Toolbar>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                    <Paper variant="outlined">
                        <Toolbar component={Paper} style={{ display: 'flex', justifyContent: 'center' }}>
                            <Typography style={{ fontWeight: 'bold' }}>
                                RECEIPTS
                            </Typography>
                        </Toolbar>
                        <TableContainer>
                            <Table>
                                <TableBody>
                                    <TableRow>
                                        <TableCell>Principle Amount</TableCell>
                                        <TableCell>Rs.{Number(data.emi[0].princ).toFixed(2)}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>Interest Amount</TableCell>
                                        <TableCell>Rs.{Number(data.emi[0].intr).toFixed(2)}</TableCell>
                                    </TableRow>
                                    <Divider />
                                    <TableRow>
                                        <TableCell>Total Receipts</TableCell>
                                        <TableCell>Rs.{Number(data.emi[0].princ + data.emi[0].intr).toFixed(2)}</TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Paper>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Paper variant="outlined">
                        <Toolbar component={Paper} style={{ display: 'flex', justifyContent: 'center' }}>
                            <Typography style={{ fontWeight: 'bold' }}>
                                PAYMENTS
                            </Typography>
                        </Toolbar>
                        <TableContainer>
                            <Table>
                                <TableBody>
                                    <TableRow>
                                        <TableCell>Loan Paid</TableCell>
                                        <TableCell>Rs.{Number(data.loan[0].ln_amnt).toFixed(2)}</TableCell>
                                    </TableRow>
                                </TableBody>
                                <TableBody>
                                    <TableRow>
                                        <TableCell>Total Payments</TableCell>
                                        <TableCell>Rs.{Number(data.loan[0].ln_amnt).toFixed(2)}</TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Paper>
                </Grid>
            </Grid>
        </Container>
    )
}