import {AiOutlineDashboard,AiOutlineLogout} from 'react-icons/ai'
import { Divider, List, ListItemButton, ListSubheader, Toolbar, Typography } from '@mui/material'
import color from '../../constant/colors'
import React from 'react'
import {GiArchiveRegister, GiProfit} from 'react-icons/gi'
import {TbCashBanknote, TbInfoSquare} from 'react-icons/tb'
import {useNavigate} from 'react-router-dom'
import { BiCollection } from 'react-icons/bi'
import { AddModerator, Collections, EmojiPeople } from '@mui/icons-material'
import { CgOverflow } from 'react-icons/cg'
const list = [
    {
       name : 'Dashboard',
       sub_list : [
        {
            name: 'Dashboard',
            link : '/',
            icon : <AiOutlineDashboard size={24} />,
        }
       ]
    },
    {
       name : 'Account Manager',
       sub_list : [
        {
            name: 'Create Account',
            link : '/CreateLoan',
            icon : <GiArchiveRegister size={24} />,
        },
        {
            name: 'Account Details',
            link : '/AccountDetails',
            icon : <TbInfoSquare size={24} />,
        },
        {
            name: 'Collect Emi',
            link : '/CollectEmi',
            icon : <BiCollection size={24} />,
        },
        {
            name: 'Overdue',
            link : '/Overdue',
            icon : <CgOverflow size={24} />,
        },
       ]
    },
    {
        name : 'Reports',
        sub_list : [
         {
             name: 'Cash Book',
             link : '/Cashbook',
             icon : <TbCashBanknote size={24} />,
             disabled : sessionStorage.getItem('role') != 'admin' ? true : ''
         },
         {
            name: 'Upcoming Loans',
            link : '/UpcomingLoans',
            icon : <TbCashBanknote size={24} />,
            disabled : sessionStorage.getItem('role') != 'admin' ? true : ''
        },
         {
             name: 'Profit & Loss',
             link : '/ProfitLoss',
             icon : <GiProfit size={24} />,
             disabled : sessionStorage.getItem('role') != 'admin' ? true : ''
         }
        ]
     },
     {
        name : 'Employee Manager',
        sub_list : [
         {
             name: 'Add Employee',
             link : '/AddEmployee',
             icon : <AddModerator size={24} />,
             disabled : sessionStorage.getItem('role') != 'admin' ? true : ''
         },
         {
             name: 'Employee Details',
             link : '/EmployeeDet',
             icon : <EmojiPeople size={24} />,
             disabled : sessionStorage.getItem('role') != 'admin' ? true : ''
         },
         {
            name: 'Collection Details',
             link : '/CollectionDetails',
             icon : <Collections size={24} />,
             disabled : sessionStorage.getItem('role') != 'admin' ? true : ''
         }
        ]
     },
    {
        name : 'Authentication',
        sub_list : [
         {
             name: 'Sign Out',
             link : '/SignOut',
             icon : <AiOutlineLogout size={24} />,
         }
        ]
     },
]

export default function NavList(){
    const navigate = useNavigate()
    const [selected, setSelected] = React.useState({main : 0, sub : 0})

    const handleRoute = (item,main,sub)=>{
        navigate(item.link)
        setSelected({
            main : main,
            sub: sub
        })
    }

    return(
        <>
        {
            list.map((item,index)=>
                <List
                    key={index}
                    component="nav"
                    subheader={
                        <ListSubheader component={'div'} style={{fontWeight: 600,padding: 0,margin: 0}} id="nested-list-subheader">
                            {item.name}
                        </ListSubheader>
                    }
                    style={{
                        padding: 10,
                        paddingLeft: 20
                    }}
                >
                {
                    item.sub_list != null ? (
                    <List>
                    {
                        item.sub_list.map((sub_item,sub_index)=>
                            <ListItemButton onClick={()=>handleRoute(sub_item,index,sub_index)} disabled={sub_item.disabled} key={sub_index} style={{marginLeft: 15,borderRadius: 10, backgroundColor: index === selected.main && sub_index === selected.sub ? color.secondary : '',color: index === selected.main && sub_index === selected.sub ? color.primary : '',marginBottom: 15  }} >
                                {sub_item.icon}
                                <Typography style={{marginLeft: 10}}>
                                    {sub_item.name}
                                </Typography>
                            </ListItemButton>
                        )
                    }
                    </List>
                    ) : ''
                }
                {
                    index + 1 === list.length ? '' : <Divider />
                }
                </List>
            )
        }
        </>
    )
}