import { Button, TextField, Box, Container, Paper, TableContainer, Table, TableHead, TableCell, TableBody, TableRow, IconButton, Toolbar, Menu, Divider, Typography, Grid } from "@mui/material";
import { FiMoreVertical } from 'react-icons/fi'
import { BiSearchAlt2 } from 'react-icons/bi'
import color from "../../../constant/colors";
import { AiTwotoneEdit } from 'react-icons/ai'
import { IoQrCode } from 'react-icons/io5'
import { CgArrowsExchangeAlt } from 'react-icons/cg'
import React from "react";
import { Api } from "../../../constant/api";
const data = [

]

export default function Dashboard() {
    const [accountInfo, setAccountInfo] = React.useState({})


    React.useEffect(() => {
        fetch(Api + 'accountInfo')
            .then(res => res.json())
            .then(res => setAccountInfo(res))
            .catch(err => {
                console.log(err)
            })
    }, [])


    return (
        <Container maxWidth="false" >
            <Grid container spacing={2}>
                <Grid item xs={12} sm={4} >
                </Grid>
                <Grid item xs={12} sm={4} component={Paper} variant="outlined">
                    <Typography variant="h5">Account Informations</Typography>
                    <Divider />
                    <TableContainer >
                        <Table>
                            <TableBody>
                                <TableRow>
                                    <TableCell>Total Loan Account</TableCell>
                                    <TableCell>{accountInfo.total_loan}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>Active Loan Account</TableCell>
                                    <TableCell>{accountInfo.active_loan}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>Closed Loan Account</TableCell>
                                    <TableCell>{accountInfo.total_loan - accountInfo.active_loan}</TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
            </Grid>
        </Container>
    )
}