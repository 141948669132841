import * as React from 'react';
import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import CssBaseline from '@mui/material/CssBaseline';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import color from '../../constant/colors';
import AppInfo from '../../appInfo.json'
import { Avatar, InputBase, LinearProgress, Paper, useMediaQuery } from '@mui/material';
import NavList from './nav_list';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Dashboard from './Dashboard';
import CreateLoan from './forms/account_create';
import AccountDetails from './masters/account_details/account_det';
import { BsSearch } from 'react-icons/bs';
import CollectEmi from './masters/account_details/collectEmi';
import AddEmployee from './forms/add_employee';
import NoAccountSelected from './masters/account_details';
import LadgerBook from './masters/account_details/account_ladger';
import AccountInformation from './masters/account_details/account_info';
import EmployeeHome from './masters/employee';
import NoAccountSelectedEm from './masters/employee/noaccount';
import EmAccountDetails from './masters/employee/account_setails';
import SignOut from '../auth/log_out';
import { Api,ImgApi } from '../../constant/api';
import { display } from '@mui/system';
import EditLoan from './masters/account_details/edit_account';
import Overdue from './masters/others/overdue';
import Cashbook from './masters/reports/cashbook';
import ProfitLoss from './masters/reports/profitLoss';
import UpcomingLoans from './masters/reports/upcomingLoans';
const drawerWidth = 240;

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open,isMobile }) => ({
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: isMobile ? `-${drawerWidth}px` : 0,
    ...(open && {
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
    }),
  }),
);

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  transition: theme.transitions.create(['margin', 'width'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: `${drawerWidth}px`,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
  justifyContent: 'space-between',

}));



export default function Home() {
    const theme = useTheme();
    const matchUpMd = useMediaQuery(theme.breakpoints.up('md'));
  const [open, setOpen] = React.useState(matchUpMd ? true : false);
  const [user, setUser] = React.useState({})
  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  React.useEffect(()=>{
    fetch(Api+'employee_info',{
      method : 'POST',
      body : JSON.stringify({
        id : sessionStorage.getItem('id')
      })
    })
    .then(res=>res.json())
    .then(res=>setUser(res))
    .catch(err=>{
       console.log(err)
    })
  },[])

  return (
    <Box>
      <CssBaseline />
      <AppBar
        position="fixed" 
        open={open}
        style={{
            backgroundColor: color.white,
            color: color.black,
            display : 'flex',
            flexDirection : 'row',
            justifyContent : 'space-between'
        }}
        elevation={0}
      >
        <Toolbar>
          
          {
            matchUpMd ? <Typography style={{color: color.black, ...(open && {display: 'none'})}} variant="h6" noWrap component="div">
            {AppInfo.name}
          </Typography> : ''
          }
          
          <IconButton 
            onClick={open ? handleDrawerClose : handleDrawerOpen}
            sx={{ mr: 0,
                backgroundColor: color.secondary,
                color: color.primary,
                marginLeft: matchUpMd ? 5 : 0
                }}
        >
            {open ? <ChevronLeftIcon /> : <MenuIcon />}
          </IconButton>
        </Toolbar>
        <Paper variant="outlined" style={{marginTop: 3,marginRight: 20}}>
          <Toolbar>
            <Avatar>
            <img src={ImgApi+user.pass_img} width="100%" />
            </Avatar>
            <div style={{marginLeft: 10}}>
              <Typography>{user.name}</Typography>
              <Typography>{user.role}</Typography>
            </div>
          </Toolbar>
        </Paper>
      </AppBar>
      <Box sx={{ display: 'flex' }} >
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: drawerWidth,
            boxSizing: 'border-box',
          },
        }}
        anchor="left"
        open={open}
        PaperProps={{style: {border: 'none'}}}
        variant={matchUpMd ? 'persistent' : 'temporary'}
        onClose={handleDrawerClose}
        
        
      >
        <DrawerHeader style={{height: '4vh',margin:0}}>
          <Typography style={{color: color.black,marginRight: 10,fontWeight: 600,marginLeft: 15}} variant="h6" noWrap component="div">
            {AppInfo.name}
          </Typography>
          
        </DrawerHeader>
        <div className='App-test'>
        <NavList />
        </div>
      </Drawer>
      <Main 
        open={open}
        style={{
            backgroundColor : color.white,
            maxHeight: '94vh',
            marginTop: 50,
            maxWidth : '100%',
            padding: matchUpMd ? 20 : 0     
        }}
        isMobile={matchUpMd}
      >
        <Box
            style={{
                backgroundColor : color.background,
                height: '90vh',
                borderRadius: 15,
                
                overflow: 'auto',
               
            }}
        >
            <div style={{padding: 20}}>
            <Routes>
                <Route path='/' element={<Dashboard />} />
                <Route path='/CreateLoan' element={<CreateLoan />} />
                <Route path='/AccountDetails' element={<AccountDetails />} >
                  <Route index element={<NoAccountSelected />} />
                  <Route path="CollectEmi" element={<CollectEmi />} />
                  <Route path='CreateLoan' element={<CreateLoan />} />
                  <Route path='LadgerBook' element={<LadgerBook />} />
                  <Route path='AccountInformation' element={<AccountInformation />} />
                  <Route path='EditLoan' element={<EditLoan />} />
                </Route>
                <Route path='/CollectEmi' element={<CollectEmi />} />
                <Route path='/Overdue' element={<Overdue />} />
                <Route path='/addEmployee' element={<AddEmployee />} />
                <Route path='EmployeeDet' element={<EmployeeHome />} >
                    <Route index element={<NoAccountSelectedEm />} />
                    <Route path='EmDet' element={<EmAccountDetails />} />
                </Route>
                <Route path="Cashbook" element={<Cashbook />} />
                <Route path="UpcomingLoans" element={<UpcomingLoans />} />

                <Route path="ProfitLoss" element={<ProfitLoss />} />
                <Route path="SignOut" element={<SignOut />} />
            </Routes>
            </div>
        </Box>
      </Main>
      </Box>
    </Box>
  );
}