import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { CircularProgress, Paper } from '@mui/material';
import { Api } from '../../constant/api';
import SnakBar from '../../constant/message';

function Copyright(props) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'Copyright © '}
      <Link color="inherit" href="https://mui.com/">
        Your Website
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const theme = createTheme();

export default function SignIn() {
  const [data, setData] = React.useState({})
  const [err, setErr] = React.useState({})
  const [loading,setLoading] = React.useState(false)
  const [massg,setMassg] = React.useState({})
  // const onChange = (e)=>{
  //   setData({
  //     ...data,
  //     [e.target.name] : e.target.value
  //   })
  // }

  

  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    console.log({
      email: data.get('user'),
      password: data.get('password'),
    });

    let err = {}

    if(!data.get('user')){
      
      err['user'] = true
    }
    if(!data.get('password')){
      
      err['password'] = true
    }

    setErr(err)
    setLoading(true)
    fetch(Api+'auth',{
      method : 'POST',
      body : data
    })
    .then(res=>res.json())
    .then(res=>{
      setLoading(false)
      if(res.code === 200){
        setLoading(true)
        sessionStorage.setItem('role',res.role)
        sessionStorage.setItem('id',res.id)
        sessionStorage.setItem('authState',true)
        setTimeout(()=>{
          setLoading(false)
          window.location.replace('/')
        },2000)
      }else{
        setMassg({
          open : true,
          massg: "access denined",
          severity : 'error'
        })
      }
    })
    .catch(err=>{
       console.log(err)
       setLoading(false)
       setMassg({
        open : true,
        massg: "Faild to connect to the server",
        severity : 'error'
      })
    })


  };

  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="xs" style={{paddingTop: 100}}>
        <CssBaseline />
        <Paper variant='outlined' style={{padding: 15,borderRadius: 20}}>
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
          
        >
          <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            DRISTI VEHICLE LOGIN
          </Typography>
          <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
            <TextField
              margin="normal"
              required
              fullWidth
              id="user"
              label="Username"
              name="user"
              autoComplete="user"
              autoFocus
              error={err.user}

            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              autoComplete="current-password"
              error={err.password}
            />
            {/* <FormControlLabel
              control={<Checkbox value="remember" color="primary" />}
              label="Remember me"
            /> */}
            {
              loading ? <CircularProgress style={{alignSelf: 'center'}} /> : ''
            }
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              Sign In
            </Button>
            {/* <Grid container>
              <Grid item xs>
                <Link href="#" variant="body2">
                  Forgot password?
                </Link>
              </Grid>
              <Grid item>
                <Link href="#" variant="body2">
                  {"Don't have an account? Sign Up"}
                </Link>
              </Grid>
            </Grid> */}
          </Box>
        </Box>
        </Paper>
        {/* <Copyright sx={{ mt: 8, mb: 4 }} /> */}
      </Container>
      <SnakBar setMassg={setMassg} massg={massg} />
    </ThemeProvider>
  );
}