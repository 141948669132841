import React, { useState } from 'react';
import { Button, Checkbox, Container, IconButton, LinearProgress, Menu, MenuItem, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Toolbar, Typography } from '@mui/material';
import { Api } from '../../../../constant/api';
import SnakBar from '../../../../constant/message';
import DateHandler from '../../../../constant/date_handler';

function UpcomingLoans() {
    const [loading, setLoading] = useState(false);
    const [massg, setMassg] = useState({});
    const [upcomingData, setUpcomingData] = useState([]);
    const [anchorEl, setAnchorEl] = useState(null);

    const handleSearch = (days) => {
        setLoading(true);
        fetch(Api + 'upcomingLoans', {
            method: 'POST',
            body: JSON.stringify({ days: days })
        })
            .then(res => {
                if (!res.ok) {
                    throw new Error('Network response was not ok');
                }
                return res.json();
            })
            .then(data => {
                setLoading(false);
                setUpcomingData(data.map(item => ({ ...item, checked: false })));
            })
            .catch(err => {
                setLoading(false);
                setMassg({
                    open: true,
                    severity: 'error',
                    text: 'Failed to connect to the server'
                });
            });
    };

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = (days) => {
        setAnchorEl(null);
        if (days !== undefined) {
            handleSearch(days);
        }
    };

    const onApprove = () => {
        setLoading(true);
        fetch(Api + 'sendLoanSms', {
            method: 'POST',
            body: JSON.stringify({ data: upcomingData.filter(item => item.checked) })
        })
            .then(res => {
                if (!res.ok) {
                    throw new Error('Network response was not ok');
                }
                return res.json();
            })
            .then(data => {
                setMassg({
                    open: true,
                    severity: 'success',
                    massg: 'Reminder sms Sent Successfully'
                });
                setLoading(false);
            })
            .catch(err => {
                setLoading(false);
                setMassg({
                    open: true,
                    severity: 'error',
                    massg: 'Failed to connect to the server'
                });
            });
    };

    const formatDate = (dateString) => {
        const [day, month, year] = dateString.split('/').map(Number);
        const currentYear = new Date().getFullYear();
        const currentMonth = new Date().getMonth() + 1; // getMonth() is zero-based, so add 1
        return `${day}/${currentMonth}/${currentYear}`;
    };

    const handleToggle = (index) => {
        setUpcomingData(prevState => {
            const newState = [...prevState];
            newState[index].checked = !newState[index].checked;
            return newState;
        });
    };

    const handleToggleAll = () => {
        setUpcomingData(prevState => {
            const allChecked = prevState.every(item => item.checked);
            return prevState.map(item => ({ ...item, checked: !allChecked }));
        });
    };
    console.log(upcomingData)

    return (
        <Container maxWidth={false} style={{ minHeight: '83vh', padding: 0, backgroundColor: '#F4F6F9' }}>
            <Toolbar component={Paper} style={{ display: 'flex', justifyContent: 'space-between' }}>
                <div>

                </div>
                <Typography style={{ fontWeight: 'bold' }}>
                    Upcoming Loans
                </Typography>
                <div>
                    <Button
                        variant='outlined'
                        color='primary'
                        onClick={handleClick}
                        style={{ marginRight: 10 }}
                        size='small'
                    >
                        Search
                    </Button>
                    <Menu
                        anchorEl={anchorEl}
                        keepMounted
                        open={Boolean(anchorEl)}
                        onClose={() => handleClose()}
                    >
                        <MenuItem onClick={() => handleClose(1)}>Next 5 Days</MenuItem>
                        
                    </Menu>

                    <Button onClick={handleToggleAll} size='small' variant="contained" color="secondary" style={{ marginRight: 5 }}>
                        Select All
                    </Button>
                    <Button onClick={onApprove} size='small' variant="contained" color="primary">
                        Send SMS
                    </Button>
                </div>
            </Toolbar>
            {loading && <LinearProgress style={{ backgroundColor: 'red' }} />}
            <TableContainer component={Paper} elevation={0}>
                <Table>
                    <TableHead>
                        <TableRow style={{ backgroundColor: '#888888' }}>
                            <TableCell style={{ color: 'white' }}>Sl No.</TableCell>
                            <TableCell style={{ color: 'white' }}>Loan No.</TableCell>
                            <TableCell style={{ color: 'white' }}>Name</TableCell>
                            <TableCell style={{ color: 'white' }}>Amount</TableCell>
                            <TableCell style={{ color: 'white' }}>Status</TableCell>
                            <TableCell style={{ color: 'white' }}>Due Date</TableCell>
                            <TableCell style={{ color: 'white' }}></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {upcomingData.map((row, index) => (
                            <TableRow key={row.id}>
                                <TableCell>{index + 1}</TableCell>
                                <TableCell>{row.id}</TableCell>
                                <TableCell>{row.name}</TableCell>
                                <TableCell>Rs. {(Number(row.princ_amount) + Number(row.int_amount)).toFixed(2)}</TableCell>
                                <TableCell ><span style={{color:'white',backgroundColor:'green',padding:5,borderRadius:20}}>{row.status}</span></TableCell>
                                <TableCell>{row.open_date ? formatDate(DateHandler(row.open_date)) : 'Due Date'}</TableCell>
                                <TableCell>
                                    <Checkbox
                                        checked={row.checked || false}
                                        onChange={() => handleToggle(index)}
                                    />
                                </TableCell>
                                
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <SnakBar massg={massg} setMassg={setMassg} />
        </Container>
    );
}

export default UpcomingLoans;
